<template>
  <v-app>
    <div class="row"  v-if="accountHolderDetail">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Manual Enrolment for practical exams
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manual enrolment
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="mb-1">
                  <strong>Full name</strong>:
                  <span @click="goToAccountHolderPage(accountHolderDetail.id,0)">{{accountHolderDetail.full_name ? accountHolderDetail.full_name : '-'}}</span>
                  <a class="ml-2 badge badge-primary text-white" v-if="school_pay_button_enabled">
                    School pay
                  </a>
                </div>
                <div class="mb-1" v-copy="accountHolderDetail.email ? accountHolderDetail.email : null">
                  <strong> Email</strong>:
                  {{ accountHolderDetail.email ? accountHolderDetail.email : '-'}}
                </div>
                <br>
                <div class="mb-1" v-copy="accountHolderDetail.score_name ? accountHolderDetail.score_name : null">
                  <strong>SCORE</strong>:
                  {{ accountHolderDetail.score_name ? accountHolderDetail.score_name : 'NA'}}
                </div>

                <div class="mb-1">
                  <strong>Phone</strong>:
                  {{accountHolderDetail.phone ? accountHolderDetail.phone : 'NA'}}
                </div>

              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" v-if="address_flag">
                <v-alert type="warning" dense border="left">
                  <h6>Ensure manual payment is taken at time of enrolment. Manual enrolment does not include a payment, or require payment confirmation to enrol the candidate.</h6>
                </v-alert>
              </v-col>
              <v-col cols="12" v-else>
                <v-alert type="warning" dense border="left">
                  Note: Billing address not available for the enroller. Please <a href="#!" @click="goToAccountHolderPage(accountHolderDetail.id,9)"> add </a> Billing address before enroling a candidate
                </v-alert>
              </v-col>
            </v-row>
            <div v-if="address_flag">
              <v-row class="text-center">
                <v-col cols="12" md="12" >
                  <v-switch @change="changeSubjectCode()" @blur="changeSubjectCode()"  v-model="subjectCodeSearch" label="Search by subject code or exam name"></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right" cols="12" md="2">
                  <label class="mt-2">
                    Has discount ?
                    <span class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col cols="12" md="10">
                  <v-switch v-model="cart_discount.has_discount"
                            :label="cart_discount.has_discount ? 'Enabled ':'Disabled'"
                            flat>
                  </v-switch>
                </v-col>

                <v-col class="text-right" cols="12" md="2" v-if="cart_discount.has_discount">
                </v-col>
                <v-col cols="12" md="4" v-if="cart_discount.has_discount">
                  <v-select @change="changeDiscountType()" :class="{ 'input--error-enrolment': $v.cart_discount.discount_type.$error}" label="Discount type" v-model="cart_discount.discount_type" :items="discount_types"
                            item-text="text" item-value="value" dense outlined :error="$v.cart_discount.discount_type.$error">
                  </v-select>
                  <span class="text-danger" v-if="$v.cart_discount.discount_type.$error">This information is required</span>
                </v-col>

                <v-col cols="12" md="4" v-if="cart_discount.has_discount && cart_discount.discount_type =='percentage'">
                  <v-text-field  :class="{ 'input--error-enrolment': $v.cart_discount.discount_value.$error}" label="Discount percentage (%)" dense outlined :error="$v.cart_discount.discount_value.$error" v-model="cart_discount.discount_value">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.cart_discount.discount_value.$error">This information is required</span>
                </v-col>

                <v-col cols="12" md="4" v-if="cart_discount.has_discount && cart_discount.discount_type=='fixed'">
                  <v-text-field :class="{ 'input--error-enrolment': $v.cart_discount.discount_value.$error}" label="Discount amount ($)" :error="$v.cart_discount.discount_value.$error" dense outlined v-model="cart_discount.discount_value">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.cart_discount.discount_value.$error">This information is required</span>
                </v-col>

                <v-col class="text-right" cols="12" md="2" v-if="cart_discount.has_discount">
                </v-col>

                <!--Location-->
                <v-col class="text-right" cols="12" md="2">
                  <label class="mt-2">
                    Location
                    <span class="text-danger">*</span>
                  </label>
                </v-col>

                <v-col cols="12" md="8">
                  <v-autocomplete  :class="{ 'input--error-enrolment': $v.exam.location_id.$error}" @change="changeLocation()" dense outlined label="Location" v-model="exam.location_id" :items="locations" :error="$v.exam.location_id.$error" item-value="id" item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.location_id.$error">This information is required</span>
                </v-col>
              </v-row>
              <div v-if="subjectCodeSearch">
                <v-row>
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">
                      Subject code
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field append-icon="mdi-magnify" :class="{ 'input--error-enrolment': $v.subject_code.$error}" v-on:keyup.enter="searchBySubjectCode" dense outlined v-model="subject_code" :error="subjectCodeStatus=='ERROR'" :success="subjectCodeStatus=='SUCCESS'" label="Enter subject code..." clearable
                    ></v-text-field>
                    <div v-bind:class="{'text-danger': subjectCodeStatus=='ERROR' }" v-if="subject_messages && subjectCodeStatus=='ERROR'">
                      {{subject_messages}}
                    </div>
                    <span class="text-danger" v-if="$v.subject_code.$error">This information is required</span>

                  </v-col>
                </v-row>
              </div>
              <div v-if="!subjectCodeSearch">
                <v-row>
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">
                      Category
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.category_id.$error}" @change="changeCategory" :loading="categoryLoading" dense outlined label="Category" v-model="exam.category_id" :items="categories" item-value="id" item-text="name"
                                    :error="$v.exam.category_id.$error"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.category_id.$error">This information is required</span>
                  </v-col>
                </v-row>
                <v-row v-if="instrument_flag">
                  <v-col class="text-right" cols="12" md="2">
                    <label  class="mt-2">
                      Instrument family
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.instrument_id.$error}" @change="changeInstrument" :loading="instrumentLoading" dense outlined label="Instrument" v-model="exam.instrument_id" :items="instruments" :error="$v.exam.instrument_id.$error" item-value="id" item-text="name"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.instrument_id.$error">This information is required</span>
                  </v-col>
                </v-row>
                <v-row v-if="syllabus_flag">
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">
                      Syllabus
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.syllabus_id.$error}"  @change="changeSyllabus" :loading="syllabusLoading" dense outlined label="Syllabus" v-model="exam.syllabus_id" :items="syllabuses" item-value="id" item-text="name" :error="$v.exam.syllabus_id.$error"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.syllabus_id.$error">This information is required</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">
                      Grade
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.grade_id.$error}"  @change="changeGrade" dense outlined label="Grade" v-model="exam.grade_id" :items="grades" item-value="id" item-text="name" :error="$v.exam.grade_id.$error"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.grade_id.$error">This information is required</span>
                  </v-col>
                </v-row>
                <v-row v-if="exam_type_flag">
                  <v-col class="text-right" cols="12" md="2">
                    <label  class="mt-2">
                      Exam type
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.exam_type_id.$error}"  @change="changeExamType" dense outlined label="Exam type" v-model="exam.exam_type_id" :items="exam_types" item-value="id" :error="$v.exam.exam_type_id.$error" item-text="name"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.exam_type_id.$error">This information is required</span>

                  </v-col>
                </v-row>
                <v-row v-if="exam_option_flag">
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">
                      Exam option
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.exam_option_id.$error}" @change="changeExamOption" dense outlined label="Exam option" v-model="exam.exam_option_id" :items="exam_options" :error="$v.exam.exam_option_id.$error" item-value="id" item-text="name"></v-autocomplete>
                    <span class="text-danger" v-if="$v.exam.exam_option_id.$error">This information is required</span>
                  </v-col>
                </v-row>
                <v-row  v-if="categoryDetail && categoryDetail.enable_video_delivery_mode">
                  <v-col class="text-right" cols="12" md="2">
                    <label class="mt-2">Exam delivery method <span class="text-danger">*</span> </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select :class="{ 'input--error-enrolment': $v.exam.exam_delivery_method.$error}"   @change="changeExamDeliveryMethods" dense outlined label="Exam delivery method" v-model="exam.exam_delivery_method" :items="exam_delivery_methods" item-value="value" item-text="name" :error="$v.exam.exam_delivery_method.$error">

                    </v-select>
                    <span class="text-danger" v-if="$v.exam.exam_delivery_method.$error">This information is required</span>
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="productDetail && productDetail.requires_hand_selection">
                <v-col class="text-right" cols="12" md="2">
                  <label  class="mt-2" >
                    Right or left handed
                    <span class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col cols="12" md="8" >
                  <v-select :class="{ 'input--error-enrolment': $v.exam.hand_type.$error}"  v-model="exam.hand_type" dense outlined label="Right or left handed" item-value="value" item-text="text" :error="$v.exam.hand_type.$error"
                            :items="hands" >
                    <template v-slot:label>
                      Right or left handed<span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.hand_type.$error">TThis information is required</span>
                </v-col>
              </v-row>
              <v-row v-if="productDetail && productDetail.requires_guitar_selection && scoreDetail.requires_guitar_selection">
                <v-col class="text-right" cols="12" md="2">
                  <label  class="mt-2">
                    Select guitar selection
                    <span class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select :class="{ 'input--error-enrolment': $v.exam.guitar_selection.$error}"  v-model="exam.guitar_selection" dense outlined label="Select guitar selection" :error="$v.exam.guitar_selection.$error"
                            :items="guitar_selection" >
                    <template v-slot:label>
                      Guitar selection <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.guitar_selection.$error">This information is required</span>
                </v-col>
              </v-row>
              <v-row v-if="productDetail && productDetail.requires_drum_selection && scoreDetail.requires_drum_selection">
                <v-col class="text-right" cols="12" md="2">
                  <label  class="mt-2">
                    Drum selection
                    <span class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select :class="{ 'input--error-enrolment': $v.exam.drum_selection.$error}" v-if="productDetail && productDetail.requires_drum_selection" v-model="exam.drum_selection" dense outlined label="Select drum selection"
                            :items="drum_selection" :error="$v.exam.drum_selection.$error">
                    <template v-slot:label>
                      Drum selection <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.drum_selection.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="12" v-if="productDetail && productDetail.requires_drum_selection && exam.drum_selection=='electric'">
                  <v-row>
                    <v-col class="text-right" cols="12" md="2">
                      <label>Are you happy for electric drum kit?
                      </label>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-radio-group v-model="exam.can_consider_electric_drum_kit" row>
                        <v-radio
                            label="Yes"
                            value="1"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="productDetail && productDetail.requires_collaborative_instrument">
                <v-col class="text-right" cols="12" md="2">
                  <label  class="mt-2">
                    Collaborative instrument
                    <span class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select :class="{ 'input--error-enrolment': $v.exam.collaborative_instrument.$error}" v-if="productDetail && productDetail.requires_collaborative_instrument" v-model="exam.collaborative_instrument" dense outlined label=" Select Collaborative Instrument"
                            :items="collaborative_instruments" item-text="title" item-value="id" :error="$v.exam.collaborative_instrument.$error">
                    <template v-slot:label>
                      Collaborative instrument <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.collaborative_instrument.$error">This information is required</span>
                </v-col>
              </v-row>
              <div  v-if="productDetail">
                <v-row>
                  <v-col class="text-right" cols="12" md="2">
                    <label  class="mt-2">
                      Available session
                      <span class="text-danger">*</span>
                    </label>
                  </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="alert" style="cursor:pointer;" v-if="exam_sessions.length > 0" v-for="(item,index) in exam_sessions" v-bind:class="checkExamSessionClass(item.enrolment_stage)" @click="setExamSession(item.id)">
                          <input :class="{ 'input--error-enrolment': $v.session.exam_session_id.$error}"  type="radio" v-model="session.exam_session_id" :value="item.id">
                          <span class="ml-3 font-medium">{{ item.name }}</span> <br/>
                          <span class="ml-8 font-medium">{{ item.formatted_start_date }} - {{ item.formatted_end_date }}</span> <br/>
                          <span class="ml-8 font-medium" v-if="item.formatted_closing_date"><b>Closing date:</b> {{ item.formatted_closing_date }}</span><br/>
                          <span class="ml-8 font-medium" v-if="item.late_closing_fee && item.enrolment_stage=='running_late'"><b>Late fee:</b> {{currency}}{{item.late_closing_fee}}</span><br/>
                          <span class="ml-8 font-medium" v-if="item.late_closing_date_format && item.enrolment_stage=='running_late'"><b>Late closing date:</b> {{item.late_closing_date_format }}</span>
                          <div v-if="item.late_closing_fee && item.enrolment_stage=='running_late' && examSessionDetail && examSessionDetail.late_closing_fee && examSessionDetail.enrolment_stage=='running_late'">
                            <v-checkbox v-model="has_late_fee" label="Has late fee " >
                            </v-checkbox>
                          </div>


                        </div>

                        <div v-if="exam_sessions.length < 1">No exam sessions available to continue</div>
                        <span class="text-danger" v-if="$v.session.exam_session_id.$error">Exam session must be selected</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row  >

                  <v-col cols="12" class="text-center">
                    <h3> {{productDetail.name}}</h3>
                  </v-col>
                  <v-col class="text-right" cols="12" md="2">
                    <!--                    <label  class="mt-2">-->
                    <!--                      Candidate information-->
                    <!--                    </label>-->
                  </v-col>
                  <v-col class="8">
                    <v-expansion-panels>
                      <v-expansion-panel v-for="(candidate_item,index) in items" :key="index">
                        <v-expansion-panel-header>
                          Candidate {{index+1}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col cols="12">

                            <v-col cols="12" md="12" >
                              <v-row >
                                <v-col :cols="index == 0 ? '12' : '9'">
                                  <h3>Candidate information</h3>
                                </v-col>
                                <v-col cols="3" class="text-right" v-if="index !== 0">
                                  <i @click="removeCandidate(index)" class="fa fa-close" style="color: red"></i>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <label>Candidate UID</label>
                                </v-col>
                                <v-col cols="12" md="9">
                                  <v-text-field outlined dense placeholder="UniqueID" v-model="candidate_item.unique_number"  :error="candidate_item.userStatus=='ERROR'"
                                                v-on:keyup.enter="findByUniqueNumber(index)" label="UniqueID">
                                  </v-text-field>
                                  <span v-bind:class="{'text-success':candidate_item.userStatus=='SUCCESS', 'text-danger': candidate_item.userStatus=='ERROR' }" v-if="candidate_item.messages">
                                                {{candidate_item.messages}}
                                      </span>
                                </v-col>
                                <v-col cols="12" md="3" >
                                  <label>First name <span class="text-danger">*</span> </label>
                                </v-col>
                                <v-col cols="12" md="9" >
                                  <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.first_name.$error}"  outlined dense placeholder="First name" :error="$v.items.$each[index].candidate.first_name.$error"  v-model="candidate_item.candidate.first_name" label="First name">
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.items.$each[index].candidate.first_name.$error">This information is required</span>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <label>Middle name </label>
                                </v-col>
                                <v-col cols="12" md="9">
                                  <v-text-field outlined dense placeholder="Candidate middle name" label="Middle name" v-model="candidate_item.candidate.middle_name"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" >
                                  <label>Surname <span class="text-danger">*</span> </label>
                                </v-col>
                                <v-col cols="12" md="9" >
                                  <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.last_name.$error}" :error="$v.items.$each[index].candidate.first_name.$error" v-model="candidate_item.candidate.last_name" outlined dense  placeholder="Surname" label="Surname" ></v-text-field>
                                  <span class="text-danger" v-if="$v.items.$each[index].candidate.last_name.$error">This information is required</span>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <label>Date of birth <span class="text-danger">*</span></label>
                                </v-col>
                                <v-col cols="12" md="9">
                                  <v-row>
                                    <v-col cols="4">
                                      <v-select :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.day.$error}" label="Select day" :items="days"  v-model="candidate_item.candidate.day" outlined dense :error="$v.items.$each[index].candidate.day.$error">
                                      </v-select>
                                      <span class="text-danger" v-if="$v.items.$each[index].candidate.day.$error">This information is required</span>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.day.$error}"   label="Select month" :items="months"  item-text="text" item-value="value" dense outlined :error="$v.items.$each[index].candidate.month.$error"
                                                v-model="candidate_item.candidate.month"
                                      ></v-select>
                                      <span class="text-danger" v-if="$v.items.$each[index].candidate.month.$error">This information is required</span>

                                    </v-col>
                                    <v-col cols="4">
                                      <v-select :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.year.$error}" v-model="candidate_item.candidate.year"    label="Select year" :items="years" item-text="text" item-value="value" outlined dense :error="$v.items.$each[index].candidate.year.$error">
                                      </v-select>
                                      <span class="text-danger" v-if="$v.items.$each[index].candidate.year.$error">This information is required</span>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="3"  v-if="scoreDetail && scoreDetail.gender_identity">
                                  <label>Gender <span class="text-danger">*</span></label>
                                </v-col>
                                <v-col cols="12" md="9" v-if="scoreDetail && scoreDetail.gender_identity">
                                  <v-select  outlined dense placeholder="Gender" label="Select gender" v-model="candidate_item.candidate.gender" @change="changeGender(index)"
                                             :error="$v.items.$each[index].candidate.gender.$error" :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.gender.$error}"
                                             :items="genders" item-text="text" item-value="value" >
                                  </v-select>
                                  <span class="text-danger" v-if="scoreDetail && scoreDetail.gender_identity && $v.items.$each[index].candidate.gender.$error">This information is required</span>

                                  <v-text-field  label="Self described" dense outlined v-model="gender_self_described"
                                                 v-if="scoreDetail && scoreDetail.gender_identity && candidate_item.candidate.gender=='self-described'">
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled &&  candidate_item.candidate.preferred_pronouns_flag">
                                  <label>Preferred pronouns <span class="text-danger">*</span></label>
                                </v-col>
                                <v-col cols="12" md="9" v-if="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled &&  candidate_item.candidate.preferred_pronouns_flag">
                                  <v-select :class="{ 'input--error-enrolment':  $v.items.$each[index].candidate.preferred_pronouns.$error}"  label="Preferred pronouns"  :items="preferred_pronouns" item-text="text" item-value="value" :error="$v.items.$each[index].candidate.preferred_pronouns.$error" dense outlined v-model="candidate_item.candidate.preferred_pronouns">
                                  </v-select>
                                  <span class="text-danger" v-if="$v.items.$each[index].candidate.preferred_pronouns.$error">This information is required</span>

                                  <v-text-field  label="Self described" dense outlined v-model="prefer_to_described" v-if="scoreDetail && scoreDetail.gender_identity && candidate_item.candidate.preferred_pronouns_flag && scoreDetail.preferred_pronoun_enabled && candidate_item.candidate.preferred_pronouns=='self-described'">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.purchase_prefix=='SANT'">
                                  <label>AMEB candidate number </label>
                                </v-col>
                                <v-col cols="9" v-if="scoreDetail && scoreDetail.purchase_prefix=='SANT'">
                                  <v-text-field label="AMEB candidate number" v-model="candidate_item.recognition.ameb_candidate_number"  dense outlined clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3"  v-if="scoreDetail && scoreDetail.purchase_prefix=='QLD'">
                                  <label>LUI number </label>
                                </v-col>
                                <v-col cols="9" v-if="scoreDetail && scoreDetail.purchase_prefix=='QLD'">
                                  <v-text-field label="LUI number" v-model="candidate_item.recognition.lui_number_qld" dense outlined clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3"  v-if="scoreDetail && scoreDetail.purchase_prefix=='QLD'">
                                  <label>Year of registration </label>
                                </v-col>
                                <v-col cols="9" v-if="scoreDetail && scoreDetail.purchase_prefix=='QLD'">
                                  <v-text-field label="Year of registration" v-model="candidate_item.recognition.registration_year_qld" dense outlined clearable></v-text-field>
                                </v-col>
                                <!--Tasmania Information-->
                                <v-row  v-if="scoreDetail && scoreDetail.purchase_prefix=='TAS'">
                                  <v-col cols="12" md="3"></v-col>
                                  <v-col cols="9">
                                    <v-checkbox @change="updateTascData(index)" v-model="candidate_item.candidate.education_authority_submission_allowed" hide-details class="shrink mr-2 mt-0">
                                      <template v-slot:label>
                                        Do you want to add eligible points added to your TCE? Grades 4 and upwards are applicable.
                                      </template>
                                    </v-checkbox>
                                  </v-col>

                                  <!-- TASC ID -->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>TASC ID (if known)</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.tasc_id_tas.$error}"  outlined dense placeholder="TASC ID (if known)" :error="$v.items.$each[index].candidate.tasc_id_tas.$error" v-model="candidate_item.candidate.tasc_id_tas" clearable>
                                      <template v-slot:label>
                                        TASC ID (if known) <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.tasc_id_tas.$error">This information is required</span>

                                  </v-col>
                                  <!-- TASC Candidate’s postal address-->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>Candidate’s postal address</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.address_line_1.$error}" outlined dense placeholder="Candidate’s postal address" v-model="candidate_item.candidate.address_line_1" clearable :error="$v.items.$each[index].candidate.address_line_1.$error">
                                      <template v-slot:label>
                                        Candidate’s postal address <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.address_line_1.$error">This information is required</span>

                                  </v-col>
                                  <!-- TASC Address line 2-->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>Address line 2</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-text-field outlined dense placeholder="Address line 2" v-model="candidate_item.candidate.address_line_2"  clearable>
                                      <template v-slot:label>
                                        Address line 2
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <!-- TASC Suburb -->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>Suburb</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.suburb.$error}" outlined dense placeholder="Suburb" v-model="candidate_item.candidate.suburb" clearable :error="$v.items.$each[index].candidate.suburb.$error">
                                      <template v-slot:label>
                                        Suburb <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.suburb.$error">This information is required</span>

                                  </v-col>
                                  <!-- TASC State -->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>State</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-select :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.state_id.$error}" dense outlined v-model="candidate_item.candidate.state_id" :items="states" item-text="name" item-value="id" label="State" clearable :error="$v.items.$each[index].candidate.state_id.$error">
                                      <template v-slot:label>
                                        State <span class="text-danger">*</span>
                                      </template>
                                    </v-select>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.state_id.$error">This information is required</span>

                                  </v-col>
                                  <!-- TASC Postcode -->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <label>Postcode</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.post_code.$error}" outlined dense placeholder="Postcode" v-model="candidate_item.candidate.post_code" :error="$v.items.$each[index].candidate.post_code.$error" clearable>
                                      <template v-slot:label>
                                        Post code <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.post_code.$error">This information is required</span>

                                  </v-col>
                                  <!-- TASC Agreement -->
                                  <v-col cols="12" md="3" v-if="candidate_item.candidate.education_authority_submission_allowed">

                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.candidate.education_authority_submission_allowed">
                                    <v-checkbox :class="{ 'input--error-enrolment': $v.items.$each[index].candidate.tasc_agreement.$error }" outlined dense v-model="candidate_item.candidate.tasc_agreement"  clearable :error="$v.items.$each[index].candidate.tasc_agreement.$error">
                                      <template v-slot:label>
                                        The Candidate agrees to relevant personal details and qualifications being provided to the Tasmanian Qualifications Authority for recording relevant points on their Tasmanian Certificate of Education <span class="text-danger">*</span>
                                      </template>
                                    </v-checkbox>
                                    <span class="text-danger" v-if="$v.items.$each[index].candidate.tasc_agreement.$error">This information is required</span>
                                  </v-col>
                                </v-row>


                                <v-row v-if="scoreDetail && scoreDetail.enable_enrolment_teacher_information">
                                  <v-col cols="12" md="3">
                                    <label><b>Candidate teacher</b></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-checkbox v-model="candidate_item.teacher_information.is_teacher" @change="changeTeacherDetail(index)">
                                      <template v-slot:label>
                                        <div>
                                          Enroller is the teacher of this candidate
                                        </div>
                                      </template>
                                    </v-checkbox>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Teacher first name <span class="text-danger" v-if="teacher_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].teacher_information.teacher_first_name.$error}" outlined dense placeholder="Teacher first name" label="Teacher first name"  v-model="candidate_item.teacher_information.teacher_first_name" :error="$v.items.$each[index].teacher_information.teacher_first_name.$error">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].teacher_information.teacher_first_name.$error">This information is required</span>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <label>Teacher surname <span class="text-danger" v-if="teacher_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].teacher_information.teacher_last_name.$error}" outlined dense placeholder="Surname" label="Surname"  v-model="candidate_item.teacher_information.teacher_last_name" :error="$v.items.$each[index].teacher_information.teacher_last_name.$error"></v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].teacher_information.teacher_last_name.$error">This information is required</span>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Teacher email <span class="text-danger" v-if="teacher_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].teacher_information.teacher_email.$error || !$v.items.$each[index].teacher_information.teacher_email.email}" outlined dense placeholder="Email" label="Email"  v-model="candidate_item.teacher_information.teacher_email" :error="$v.items.$each[index].teacher_information.teacher_email.$error || !$v.items.$each[index].teacher_information.teacher_email.email"></v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].teacher_information.teacher_email.$error">This information is required</span>
                                    <p class="text-danger" v-if="!$v.items.$each[index].teacher_information.teacher_email.email">This information is invalid</p>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label> Teacher phone<span class="text-danger" v-if="teacher_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].teacher_information.teacher_contact.$error}" outlined dense placeholder="Teacher phone" label="Teacher phone (mobile preferred)"  v-model="candidate_item.teacher_information.teacher_contact" :error="$v.items.$each[index].teacher_information.teacher_contact.$error"></v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].teacher_information.teacher_contact.$error">This information is required</span>
                                  </v-col>
                                  <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.require_school_name_on_enrolment_form">
                                    <label>Teacher school name <span class="text-danger" v-if="teacher_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="scoreDetail && scoreDetail.require_school_name_on_enrolment_form">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].teacher_information.teacher_school_name.$error}" outlined dense placeholder="Teacher school name" label="Teacher school name"  v-model="candidate_item.teacher_information.teacher_school_name" :error="$v.items.$each[index].teacher_information.teacher_school_name.$error"></v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].teacher_information.teacher_school_name.$error">This information is required</span>
                                  </v-col>
                                </v-row>
                                <v-row v-if="scoreDetail && scoreDetail.enable_enrolment_parent_information">
                                  <v-col cols="12" md="3">
                                    <label><b>Candidate parent/guardian</b></label>
                                  </v-col>
                                  <v-col cols="12" md="9">

                                    <v-checkbox v-model="candidate_item.parent_information.is_parent" @change="changeParentDetail(index)">
                                      <template v-slot:label>
                                        <div>
                                          Enroller is the parent/guardian of this candidate
                                        </div>
                                      </template>
                                    </v-checkbox>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Parent first name <span class="text-danger" v-if="parent_validation_colour_flag">*</span>  </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].parent_information.parent_first_name.$error}" outlined dense placeholder="First name" label="First name"  v-model="candidate_item.parent_information.parent_first_name" :error="$v.items.$each[index].parent_information.parent_first_name.$error">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].parent_information.parent_first_name.$error">This information is required</span>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <label>Parent surname <span class="text-danger" v-if="parent_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field outlined dense placeholder="Surname" label="Surname" :class="{ 'input--error-enrolment': $v.items.$each[index].parent_information.parent_last_name.$error}" v-model="candidate_item.parent_information.parent_last_name" :error="$v.items.$each[index].parent_information.parent_last_name.$error">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].parent_information.parent_last_name.$error">This information is required</span>

                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Parent email <span class="text-danger" v-if="parent_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].parent_information.parent_email.$error || !$v.items.$each[index].parent_information.parent_email.email}" outlined dense placeholder="Email" label="Email"  v-model="candidate_item.parent_information.parent_email" :error="$v.items.$each[index].parent_information.parent_email.$error || !$v.items.$each[index].parent_information.parent_email.email">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].parent_information.parent_email.$error">This information is required</span>
                                    <p class="text-danger" v-if="!$v.items.$each[index].parent_information.parent_email.email">This information is required</p>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Parent phone <span class="text-danger" v-if="parent_validation_colour_flag">*</span> </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].parent_information.parent_contact.$error}" outlined dense placeholder="Phone (mobile preferred)" label="Phone (mobile preferred)"  v-model="candidate_item.parent_information.parent_contact" :error="$v.items.$each[index].parent_information.parent_contact.$error">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].parent_information.parent_contact.$error">This information is required</span>
                                  </v-col>
                                </v-row>

                                <v-row v-if="productDetail && productDetail.has_external_invigilator">
                                  <v-col cols="12" md="12">
                                    <label><b>Candidate invigilator information</b></label>
                                  </v-col>
                                  <v-col cols="12" md="3" >
                                    <label>Invigilator first name  <span class="text-danger">*</span></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field  :class="{ 'input--error-enrolment': $v.items.$each[index].invigilator_information.invigilator_first_name.$error}" :error="$v.items.$each[index].invigilator_information.invigilator_first_name.$error" outlined dense placeholder="Invigilator first name" label="Invigilator first name"  v-model="candidate_item.invigilator_information.invigilator_first_name">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].invigilator_information.invigilator_first_name.$error">This information is required</span>
                                  </v-col>
                                  <v-col cols="12" md="3" >
                                    <label>Invigilator middle name </label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field outlined dense placeholder="Invigilator middle name" label="Invigilator middle name"  v-model="candidate_item.invigilator_information.invigilator_middle_name">
                                    </v-text-field>
                                  </v-col>

                                  <v-col cols="12" md="3" >
                                    <label>Invigilator surname  <span class="text-danger">*</span></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].invigilator_information.invigilator_last_name.$error}"  :error="$v.items.$each[index].invigilator_information.invigilator_last_name.$error" outlined dense placeholder="Invigilator surname" label="Invigilator surname"  v-model="candidate_item.invigilator_information.invigilator_last_name">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].invigilator_information.invigilator_last_name.$error">This information is required</span>

                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Invigilator email <span class="text-danger">*</span></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field  :class="{ 'input--error-enrolment': $v.items.$each[index].invigilator_information.invigilator_email.$error || !$v.items.$each[index].invigilator_information.invigilator_email.email}" :error="$v.items.$each[index].invigilator_information.invigilator_email.$error || !$v.items.$each[index].invigilator_information.invigilator_email.email" outlined dense placeholder="Invigilator email" label="Invigilator email"  v-model="candidate_item.invigilator_information.invigilator_email">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].invigilator_information.invigilator_email.$error">This information is required</span>
                                    <p class="text-danger" v-if="!$v.items.$each[index].invigilator_information.invigilator_email.email">This information is invalid</p>
                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label> Invigilator phone (mobile preferred)  <span class="text-danger">*</span></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-text-field :class="{ 'input--error-enrolment': $v.items.$each[index].invigilator_information.invigilator_contact.$error}" outlined dense placeholder="Invigilator contact" label="Invigilator contact" :error="$v.items.$each[index].invigilator_information.invigilator_contact.$error"  v-model="candidate_item.invigilator_information.invigilator_contact">
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.items.$each[index].invigilator_information.invigilator_contact.$error">This information is required</span>

                                  </v-col>

                                  <v-col cols="12" md="3">
                                    <label>Invigilator date of birth <span class="text-danger">*</span></label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-menu
                                        v-model="candidate_item.invigilator_information.date_menu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :error="$v.items.$each[index].invigilator_information.invigilator_dob.$error" :class="{ 'input--error-enrolment': $v.items.$each[index].invigilator_information.invigilator_dob.$error}"
                                                      v-model="candidate_item.invigilator_information.invigilator_dob"
                                                      label="Invigilator Date of birth" prepend-inner-icon="mdi-calendar"
                                                      readonly v-bind="attrs" v-on="on" dense outlined
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"  min="1950-01-01"
                                                      v-model="candidate_item.invigilator_information.invigilator_dob"
                                                      @input="candidate_item.invigilator_information.date_menu = false"
                                      ></v-date-picker>
                                    </v-menu>
                                    <span class="text-danger" v-if="$v.items.$each[index].invigilator_information.invigilator_dob.$error">This information is required</span>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" md="3">
                                    <label>Custom fees</label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-autocomplete dense outlined label="Select custom fee" v-model="candidate_item.transaction.transaction_heading_ids" :items="transaction_headers" item-text="text_content" item-value="id" multiple></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <label>Scheduling requests</label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-autocomplete @change="changeSchedulingRequest(index)" outlined dense placeholder="Scheduling requests"  :items="scheduling_requests" v-model="candidate_item.exam_request.schedule_request_id"
                                                    item-value="id" item-text="name" label="Scheduling request">
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-textarea :class="{ 'input--error-enrolment':  $v.items.$each[index].exam_request.scheduling_remarks.$error}" outlined dense placeholder="Describe your request" v-model="candidate_item.exam_request.scheduling_remarks" :error="$v.items.$each[index].exam_request.scheduling_remarks.$error"   label="Your request" v-if="candidate_item.exam_request.schedulingRequestDetail && candidate_item.exam_request.schedulingRequestDetail.require_additional_info">
                                    </v-textarea>
                                    <span class="text-danger" v-if="$v.items.$each[index].exam_request.scheduling_remarks.$error">This information is required</span>


                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <label>Special assistance</label>
                                  </v-col>
                                  <v-col cols="12" md="9">
                                    <v-switch v-model="candidate_item.exam_request.special_assistance_required"  color="primary"
                                              :label="candidate_item.exam_request.special_assistance_required?' Required ':'Not required'"
                                    ></v-switch>
                                  </v-col>
                                  <v-col cols="12" md="3" v-if="candidate_item.exam_request.special_assistance_required">
                                    <label>Describe the request</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.exam_request.special_assistance_required">
                                    <v-textarea outlined dense placeholder="Describe the request" label="Describe the request" v-model="candidate_item.exam_request.special_assistance_remarks_by_user"  v-if="candidate_item.exam_request.special_assistance_required"></v-textarea>
                                  </v-col>
                                  <v-col cols="12" md="3" v-if="candidate_item.exam_request.special_assistance_required">
                                    <label>Describe the request for Admin</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.exam_request.special_assistance_required">
                                    <v-textarea outlined dense placeholder="Describe the request for Admin" label="Describe the request for Admin" v-model="candidate_item.exam_request.special_assistance_remarks_by_admin"  v-if="candidate_item.exam_request.special_assistance_required"></v-textarea>
                                  </v-col>
                                  <v-col cols="12" md="3" v-if="candidate_item.exam_request.special_assistance_required">
                                    <label>Display on</label>
                                  </v-col>
                                  <v-col cols="12" md="4" v-if="candidate_item.exam_request.special_assistance_required">
                                    <v-checkbox v-model="candidate_item.exam_request.show_special_assistance_to_examiner">
                                      <template v-slot:label>
                                        <div>
                                          <span class="font-weight-medium  subtitle-1">Examiner timetable</span>
                                        </div>
                                      </template>
                                    </v-checkbox>
                                  </v-col>

                                  <v-col cols="12" md="4" v-if="candidate_item.exam_request.special_assistance_required">
                                    <v-checkbox v-model="candidate_item.exam_request.show_special_assistance_to_supervisor">
                                      <template v-slot:label>
                                        <div>
                                          <span class="font-weight-medium subtitle-1">Supervisor timetable</span>
                                        </div>
                                      </template>
                                    </v-checkbox>
                                  </v-col>
                                  <v-col cols="12" md="3" v-if="candidate_item.exam_request.special_assistance_required">
                                    <label>Display remarks of</label>
                                  </v-col>
                                  <v-col cols="12" md="9" v-if="candidate_item.exam_request.special_assistance_required">
                                    <v-select outlined dense :items="display_remarks_types" item-text="text" item-value="value" placeholder="Display remarks of" label="Display remarks of" v-model="candidate_item.exam_request.timetable_special_assistance_option"  v-if="candidate_item.exam_request.special_assistance_required" :menu-props="{ bottom: true, offsetY: true }">
                                    </v-select>
                                  </v-col>

                                </v-row>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="3" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">
                                  <label>Examiner name</label>
                                </v-col>
                                <v-col cols="12" md="9" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">
                                  <v-combobox  @change="checkExaminer(index)"
                                               v-model="candidate_item.exam_request.unsuitable_examiners"
                                               hide-selected outlined dense deletable-chips
                                               placeholder="Examiner COI" label="Examiner COI"
                                               multiple  small-chips>
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Type an Examiner name and press <kbd>enter</kbd> to add to this enrolment
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-combobox>
                                </v-col>

                                <v-col cols="12" md="3" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">

                                </v-col>
                                <v-col cols="12" md="9" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">
                                  <v-textarea  outlined dense v-model="candidate_item.exam_request.unsuitable_examiner_comments"  placeholder="Please list any known examiners who are past teachers, family members etc, and the context" label="Reason">
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-col>

                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row >

                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right" cols="12" md="2">

                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="12" md="12" v-if="scoreDetail && scoreDetail.enable_private_venues && examSessionDetail && !examSessionDetail.enable_private_venue && examSessionDetail.ask_private_venue_confirmation">
                        <v-col cols="12" md="12">
                          <label>Are you happy to consider a private venue for this enrolment ?
                          </label>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-radio-group
                              v-model="session.can_consider_private_venues"
                              row
                          >
                            <v-radio
                                label="Yes"
                                value="1"
                            ></v-radio>
                            <v-radio
                                label="No"
                                value="0"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-col>
                      <v-col cols="12" md="12" v-if="scoreDetail && scoreDetail.enable_private_venues && examSessionDetail && examSessionDetail.enable_private_venue">
                        <h6 class="mb-2 mt-10">
                          Private venue detail
                        </h6>
                        <v-text-field :class="{ 'input--error-enrolment': $v.venueDetail.$error}" outlined dense placeholder="Enter venue code" :success="venueStatus=='SUCCESS'" :error="venueStatus=='ERROR' || $v.venueDetail.$error"
                                      v-model="venue_code" @click="verifyVenueCode">
                          <template v-slot:label>
                            Enter private venue code
                            <span class="text-danger">*</span>
                          </template>
                        </v-text-field>
                        <span class="text-danger" v-if="$v.venueDetail.$error">This information is required</span>
                        <div v-bind:class="{'text-success':venueStatus=='SUCCESS', 'text-danger': venueStatus=='ERROR' }" v-if="venue_messages!=null">
                          {{venue_messages}}
                        </div>
                        <div v-if="venueDetail">
                          <span><b>Venue name:</b> {{ venueDetail.name}}</span> <br>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" class="text-right">
                    <v-btn @click="addCandidate" dark x-large>
                      Add candidate
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="10" class="text-right">

                    <v-btn @click="AddToCartWithoutPayment" v-if="examSessionDetail && examSessionDetail.enrolment_stage=='running_normal'"
                           text
                           x-large
                    >
                      Add to enrolers cart
                    </v-btn>
                    <v-btn @click="addToCartWithPayment"
                           dark
                           x-large
                           medium>
                      Complete enrolment
                    </v-btn>
                    <v-btn class="ml-2" @click="addToCartWithSchoolPayment" v-if="school_pay_button_enabled"
                           dark
                           x-large
                           medium>
                      Complete enrolment with SchoolPay
                    </v-btn>

                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="candidate_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-overlay :value="product_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-overlay :value="cart_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <div v-else class="text-center card-body">
      <NotFound
          :message="'Not matching enroler available'"
      ></NotFound>
    </div>

    <update-cart 
      v-if="cartId" 
      ref="update-cart" 
    ></update-cart>

  </v-app>
</template>

<script>
import NotFound from "@/view/pages/view/components/NotFound";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ProductService from "@/services/product/ProductService";
import ExamOptionService from "@/services/product/exam-option/ExamOptionService";
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import GradeService from "@/services/product/grade/GradeService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import LocationService from "@/services/practical-exam/location/LocationService";
import CategoryService from "@/services/product/category/CategoryService";
import ExamSessionService from "@/services/practical-exam/exam-session/ExamSession";
import ScoreService from "@/services/score/score/ScoreService";
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import { withParams } from 'vuelidate/lib/validators/common';
import VenueService from "@/services/practical-exam/venue/VenueService";
import EnrolPracticalExamService from "@/services/practical-exam/enrol/EnrolPracticalExamService";
import TransactionHeadingService from "@/services/transaction-heading/TransactionHeadingService";
import CollaborativeInstrumentService from "@/services/product/collaborative-instrument/CollaborativeInstrumentService";
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/setting/state/StateService";
import UpdateCart from "./UpdateCart.vue";
import cartService from "@/services/cart/cartService";

const state=new StateService();
const userAddress=new UserAddressService();
const collaborativeInstrument=new CollaborativeInstrumentService();
const transactionHeader=new TransactionHeadingService();
const enrolPracticalExam=new EnrolPracticalExamService();
const venue=new VenueService();
const schedulingRequest =new SchedulingRequestService();
const score=new ScoreService();
const examSession=new ExamSessionService();
const product=new ProductService();
const examOption=new ExamOptionService();
const examType=new ExamTypeService();
const grade=new GradeService();
const syllabus=new SyllabusService();
const instrument=new InstrumentService();
const accountHolder=new AccountHolderService();
const location =new LocationService();
const category=new CategoryService();
const cart=new cartService();

export default {
  components: {
    NotFound, UpdateCart
  },
  data(){
    return{
      exam_delivery_methods: [
        { name: 'Face to Face', value:'face_to_face' },
        { name: 'Video', value:'video' },
      ],
      accountHolderDetail:{},
      scoreId:'',

      locations:[],
      categories:[],
      instruments:[],
      syllabuses:[],
      grades:[],
      exam_types:[],
      exam_options:[],
      exam_sessions:[],
      scheduling_requests:[],
      transaction_headers:[],
      exam:{
        location_id:'',
        category_id:'',
        instrument_id:'',
        syllabus_id:'',
        grade_id:'',
        exam_type_id:'',
        exam_option_id:'',
        hand_type:'',
        guitar_selection:'',
        collaborative_instrument:'',
        can_consider_electric_drum_kit:'',
        exam_delivery_method:'',
      },
      session:{
        exam_session_id:'',
        venue_id:'',
        venue_code:'',
        can_consider_private_venues:'',
      },

      items:[
        {
          unique_number:null,
          userStatus:null,
          messages:null,
          candidate:{
            first_name:'',
            middle_name:'',
            last_name:'',
            date_of_birth:'',
            day:'',
            month:'',
            year:'',
            gender:'',
            preferred_pronouns:'',
            tasc_id_tas:'',
            address_line_1:'',
            address_line_2:'',
            suburb:'',
            post_code:'',
            state_id:'',
            education_authority_submission_allowed:false,
            tasc_agreement:true,
            preferred_pronouns_flag:false,
          },
          recognition:{
            ameb_candidate_number:'',
            lui_number_qld:'',
            registration_year_qld:'',
            tasc_id_tas:'',
            address_line_1:'',
            address_line_2:'',
            suburb:'',
            state_id:'',
            post_code:'',
          },
          teacher_information:{
            teacher_first_name:'',
            teacher_middle_name:'',
            teacher_last_name:'',
            teacher_email:'',
            teacher_contact:'',
            teacher_school_name:'',
            is_teacher:false,
          },
          parent_information:{
            parent_first_name:'',
            parent_middle_name:'',
            parent_last_name:'',
            parent_email:'',
            parent_contact:'',
            is_parent:false,
          },
          invigilator_information:{
            invigilator_first_name:'',
            invigilator_middle_name:'',
            invigilator_last_name:'',
            invigilator_contact:'',
            invigilator_email:'',
            invigilator_dob:'',
            date_menu:false,
          },
          exam_request:{
            schedule_request_id:'',
            scheduling_remarks:'',
            special_assistance_required:0,
            unsuitable_examiners:[],
            unsuitable_examiner_comments:'',
            special_assistance_remarks_by_user:'',
            special_assistance_remarks_by_admin:'',
            show_special_assistance_to_examiner:'',
            show_special_assistance_to_supervisor:'',
            timetable_special_assistance_option:'enroller',
            schedulingRequestDetail:null,
          },
          transaction:{
            transaction_heading_ids:''
          },
        }
      ],
      transaction:{
        transaction_heading_ids:''
      },

      categoryDetail:null,
      instrumentDetail:null,
      syllabusDetail:null,
      gradeDetail:null,
      examTypeDetail:null,
      examOptionDetail:null,
      productDetail:null,
      venueDetail:null,
      scoreDetail:null,
      examSessionDetail:null,

      product_price:'',

      categoryLoading:false,
      instrumentLoading:false,
      syllabusLoading:false,
      gradeLoading:false,
      examTypeLoading:false,
      examOptionLoading:false,

      grade_ids:null,

      instrument_flag:false,
      syllabus_flag:false,
      exam_type_flag:false,
      exam_option_flag:false,



      candidate_overlay:false,
      product_overlay:false,
      cart_overlay:false,

      hands:[
        {value:'left',text:'Left handed'},
        {value:'right',text:'Right handed'},
      ],
      guitar_selection:[
        {value:'electric',text:'Acoustic or electric kit ok'},
        {value:'acoustic',text:'Acoustic kit only'},
      ],
      drum_selection:[
        {value:'electric',text:'Electric drum'},
        {value:'acoustic',text:'Acoustic drum'},
      ],
      genders:[
        {value:'male',text:'Male'},
        {value:'female',text:'Female'},
        {value:'non-binary',text:'Nonbinary'},
        {value:'self-described',text:'Prefer to self-describe'},
        {value:'not_to_say',text:'Prefer not to say'},
      ],
      preferred_pronouns:[
        {value:'she',text:' She/Her'},
        {value:'he',text:'He/Him'},
        {value:'them',text:'They/Them'},
        {value:'self-described',text:'Prefer to self-describe'},
      ],
      gender_self_described:'',
      prefer_to_described:'',
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
      cart_discount:{
        has_discount:false,
        discount_type:'fixed',
        discount_value:'',

      },
      years:[],
      days:[],
      unique_number:'',
      userDetail:null,
      messages:null,
      userStatus:null,
      is_teacher:false,
      is_parent:false,
      venue_messages:null,
      venueStatus:null,
      venue_code:null,
      teacher_validation_colour_flag:false,
      parent_validation_colour_flag:false,
      address_flag:false,
      collaborative_instruments:[],
      subjectCodeSearch:false,
      subject_code:'',
      subject_messages:'',
      subjectCodeStatus:null,
      userAddressDetail:{},
      currency:'',
      states:[],
      discount_types:[
        {text:'Fixed price',value:'fixed'},
        {text:'Percentage discount',value:'percentage'},
      ],
      display_remarks_types:[
        {value:'admin',text:'Admin'},
        {value:'enroller',text:'Enroller'},
      ],
      school_pay_button_enabled:false,
      has_late_fee:false,
      cart: '',
      cartId: ''
    }
  },
  validations(){
    return this.rules;
  },
  methods:{
    changeExamDeliveryMethods(){
      this.getProductDetail();
    },
    updateTascData(index){
          this.items[index].candidate.tasc_id_tas=null;
          this.items[index].candidate.address_line_1=null;
          this.items[index].candidate.address_line_2=null;
          this.items[index].candidate.suburb=null;
          this.items[index].candidate.post_code=null;
          this.items[index].candidate.state_id=null;


    },

    getAllState()
    {
      state
          .all()
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    getAccountHolderDetail(){
      accountHolder
          .show(this.accountHolderId)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.checkBillingAddress();
              if(this.accountHolderDetail.accountholder_setting.default_billing_address_id){
                this.findUserAddress(this.accountHolderDetail.accountholder_setting.default_billing_address_id);
              }
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
              if(this.accountHolderDetail && this.accountHolderDetail.accountholder_setting){
                if(this.accountHolderDetail.accountholder_setting.has_discount){
                  this.cart_discount.discount_type=this.accountHolderDetail.accountholder_setting.discount_type;
                  this.cart_discount.discount_value=this.accountHolderDetail.accountholder_setting.discount_value;
                }
              }
            }
          })
          .catch(err => {

          }).finally(() => {
        this.categoryLoading = false;
      });
    },
    findUserAddress(addressId){
      userAddress
          .show(addressId)
          .then((response) => {
            this.userAddressDetail=response.data.address;

          })
          .catch(err => {

          })
          .finally(() => {
            this.syllabusLoading = false;
          });
    },
    //Location
    getAllLocation(){
      location
          .all({'score_id':this.scoreId})
          .then((response) => {
            this.locations=response.data.locations;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    changeLocation(){
      this.getAllCategories();
    },
    //Category
    getAllCategories(){
      let data={}
      if(this.scoreId){
        data['score_id']=this.scoreId
      }
      data['is_online']=0;
      this.exam.category_id=null
      this.categories=[]
      this.categoryLoading=true;
      category
          .getFilterData(data)
          .then((response) => {
            this.categories=response.data.categories;
          })
          .catch(err => {

          })
          .finally(() => {
            this.categoryLoading = false;
          });
    },
    changeCategory(){
      this.findCategoryDetail(this.exam.category_id)
    },
    findCategoryDetail(categoryId){
      category
          .show(categoryId)
          .then((response) => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && this.categoryDetail.has_instruments){
              this.instrument_flag=true;
              this.getAllInstruments();
            }else{
              this.instrument_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && this.categoryDetail.has_syllabuses){
              this.syllabus_flag=true;
              this.getAllSyllabus();
            }else{
              this.syllabus_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && !this.categoryDetail.has_syllabuses){
              this.grade_ids=this.categoryDetail.applicable_grades;
              this.getAllGrades();
            }
          })
          .catch(err => {

          });
    },
    //Instrument
    getAllInstruments(){
      let data={};
      data['category_id']=this.exam.category_id;
      if(this.scoreId){
        data['score_id']=this.scoreId
      }
      this.instruments=[];
      this.exam.instrument_id=null;
      this.instrumentLoading=true;
      instrument
          .getFilterData(data)
          .then((response) => {
            this.instruments=response.data.instruments;
          })
          .catch(err => {

          })
          .finally(() => {
            this.instrumentLoading = false;
          });
    },
    changeInstrument(){
      this.findInstrumentDetail(this.exam.instrument_id)
    },
    findInstrumentDetail(instrumentId){
      instrument
          .show(instrumentId)
          .then((response) => {
            this.instrumentDetail=response.data.instrument;
            if(this.instrumentDetail && this.instrumentDetail.has_syllabuses && this.categoryDetail && this.categoryDetail.has_syllabuses){
              this.syllabus_flag=true;
              this.getAllSyllabus();

            }else{
              this.syllabus_flag=false;
            }
            if(this.instrumentDetail && !this.instrumentDetail.has_syllabuses){
              this.grade_ids=this.instrumentDetail.applicable_grades;
              this.getAllGrades();
            }
          })
          .catch(err => {

          })
          .finally(() => {

          });
    },
    //Syllabus
    getAllSyllabus(){
      this.syllabuses=[];
      this.syllabusLoading=true;
      this.exam.syllabus_id=null;
      syllabus
          .getFilterData({'category_id':this.exam.category_id,'instrument_id':this.exam.instrument_id,'score_id':this.scoreId})
          .then((response) => {
            this.syllabuses=response.data.syllabuses;
          })
          .catch(err => {

          })
          .finally(() => {
            this.syllabusLoading = false;
          });
    },
    changeSyllabus(){
      this.findSyllabusDetail(this.exam.syllabus_id);
    },
    findSyllabusDetail(syllabusId){
      syllabus
          .show(syllabusId)
          .then((response) => {
            this.syllabusDetail=response.data.syllabus;
            this.grade_ids=this.syllabusDetail.applicable_grades;
            this.getAllGrades();
          })
          .catch(err => {

          });
    },
    //Grade
    getAllGrades(){
      if(this.grade_ids){
        this.grades=[];
        this.exam.grade_id=null;
        this.gradeLoading=true;
        grade
            .getGradeByIds({'grade_ids':this.grade_ids})
            .then((response) => {
              this.grades=response.data.grades;
            })
            .catch(err => {

            }).finally(() => {
          this.gradeLoading = false;
        });
      }

    },
    changeGrade(){
      this.checkExamTypeEnabled();
    },
    //Exam Type
    checkExamTypeEnabled(){
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      examType
          .checkExamTypeEnabled(data)
          .then((response) => {
            let flag=false;
            flag=response.data.flag;
            if(flag=='true' || flag==true){
              this.getAllExamType();
            }else{
              this.checkOptionTypeEnabled('0');
              this.exam_type_flag=false;
            }

          })
          .catch(err => {

          })
    },
    getAllExamType(){
      this.exam.exam_type_id=null
      this.exam_types=[];
      this.examTypeLoading=true;
      examType
          .getAllExamType({
            'category_id': this.exam.category_id,
            'instrument_id': this.exam.instrument_id,
            'syllabus_id': this.exam.syllabus_id,
            'grade_id': this.exam.grade_id,
          })
          .then((response) => {
            this.exam_types = response.data.exam_types;
            if(this.exam_types.length == 0 ) {
              this.checkOptionTypeEnabled('0');

              this.exam_type_flag=false;
            }
            if(this.exam_types.length>0){
              this.exam_type_flag=true;
            }

          })
          .catch(err => {

          }).finally(() => {
        this.examTypeLoading = false;
      });

    },
    changeExamType(){
      this.findExamTypeDetail(this.exam.exam_type_id);
    },
    findExamTypeDetail(examTypeId){
      examType
          .show(examTypeId)
          .then((response) => {
            this.examTypeDetail=response.data.exam_type;
            this.checkOptionTypeEnabled('1');

          })
          .catch(err => {

          });
    },
    //ExamOption
    checkOptionTypeEnabled(type){
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id.id;
      }
      data['type']=type;
      examOption
          .checkExamOptionEnabled(data)
          .then((response) => {
            let flag=false;
            flag=response.data.flag;
            if(flag==='true' || flag===true){
              this.getAllExamOption();
            }else{
              this.exam_option_flag=false;
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }
          })
          .catch(err => {

          })
    },
    changeExamOption(){
      if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
        this.getProductDetail();
      }

    },
    getAllExamOption(){
      this.examOptionLoading = true;
      this.exam_options=[];
      this.exam.exam_option_id='';
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_option_id){
        data['exam_option_id']=this.exam.exam_option_id;
      }
      if(this.exam.grade_id){
        data['grade_id']=this.exam.grade_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id;
      }
      this.examOptionLoading=false;
      examOption
          .getAllExamOption(data)
          .then((response) => {
            this.exam_options = response.data.exam_options;
            if(this.exam_options.length===0){
              this.exam_option_flag=false;
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }
            if(this.exam_options.length > 0){
              this.exam_option_flag=true;
            }
          })
          .catch(err => {

          }).finally(() => {
        this.examOptionLoading = false;
      });

    },
    //Product
    getProductDetail(){
      this.productDetail=null;
      let data={};
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_option_id){
        data['exam_option_id']=this.exam.exam_option_id;
      }
      if(this.exam.grade_id){
        data['grade_id']=this.exam.grade_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id;
      }
      if(this.exam.exam_delivery_method){
        data['exam_delivery_method']=this.exam.exam_delivery_method;
      }
      data['is_group_exam']=0;
      this.product_overlay=true;
      product
          .findProductDetail(data)
          .then((response) => {
            if(response.data.message){
              this.messages=response.data.message;
            }
            if(response.data.product) {
              this.productDetail = response.data.product;
              if(!this.categoryDetail.enable_video_delivery_mode){
                this.exam.exam_delivery_method=this.productDetail.exam_delivery_method;
              }
              this.getYearList();
              this.getDayList();
              this.getAllExamSession();
              this.getAllSchedulingRequest();
              this.getAllTransactionHeadings();
              this.getCollaborativeInstrumentByCategory();
              this.getProductPriceByScore();
              this.getAllState();

            }

            if(response.data.status==="SUCCESS"){

            }else{
              this.$snotify.error(this.messages);
            }
          })
          .catch((err) => {

          }).finally(() => {
        this.product_overlay = false;
      });


    },
    getProductPriceByScore(){
      let data={};
      if(this.productDetail){
        data['product_id']=this.productDetail.id;
        data['score_id']=this.scoreId;
        product
            .getProductPriceByScore(data)
            .then((response) => {
              this.product_price=response.data.product_price;

            })
            .catch(err => {

            });
      }

    },
    //Exam session
    getAllExamSession() {
      let data = {
        'location_id':  this.exam.location_id,
        'instrument_id': this.exam.instrument_id,
        'syllabus_id': this.exam.syllabus_id,
        'grade_id': this.exam.grade_id,
        'score_id':this.scoreId,
        'exam_delivery_method':this.exam.exam_delivery_method,
      };
      examSession
          .getAllExamSession(data)
          .then((response) => {
            this.exam_sessions=response.data.exam_sessions;
          })
          .catch(err => {

          });
    },
    changeExamSession(examSessionId){
      this.getExamSessionDetail(examSessionId);
    },
    setExamSession(examSessionId){
      this.session.exam_session_id = examSessionId;
      this.changeExamSession(examSessionId);
    },
    getExamSessionDetail(examSessionId){
      examSession
          .show(examSessionId)
          .then((response) => {
            this.examSessionDetail=response.data.examSession;
            this.venueDetail=null;
            this.venue_code=null;
            this.session.can_consider_private_venues="0";
          })
          .catch(err => {

          });
    },
    checkExamSessionClass(enrolmentStage){

      if(enrolmentStage=='closed'){
        return 'alert-danger';
      }else if(enrolmentStage=='running_late'){
        return 'alert-warning';
      }else{
        return 'alert-success';
      }
    },
    checkExamSessionText(enrolmentStage){
      if(enrolmentStage=='closed'){
        return 'Closed';
      }else if(enrolmentStage=='running_late'){
        return 'Late Enrolment';
      }else{
        return 'Open';
      }

    },
    checkExamSessionButtonColor(enrolmentStage){
      if(enrolmentStage=='closed'){
        return 'bg-red-100 text-red-800';
      }else if(enrolmentStage=='running_late'){
        return 'bg-yellow-100 text-yellow-80';
      }else{
        return 'bg-green-100 text-green-800';
      }
    },
    //Candidate
    getYearList() {
      let current_year=new Date().getFullYear();
      let selected_date=current_year-4;
      let year=[];
      for(let i = 0; i <= 100; i++){
        year.push(i);
      }
      if(year.length>0){
        year.forEach(y=>{
          this.years.push({'text':selected_date - y,'value':selected_date - y})
        })
      }
    },
    getDayList() {
      for(let i = 1; i < 32; i++){
        this.days.push(i);
      }
    },
    changeGender(index){
      if(this.items[index].candidate.gender=='non-binary' || this.items[index].gender=='self-described' ){
        this.items[index].candidate.preferred_pronouns_flag=true;
      }else{
        this.items[index].candidate.preferred_pronouns_flag=false;
      }

    },
    findByUniqueNumber(index){
      this.candidate_overlay=true;
      accountHolder
          .findAsCandidateUser({'unique_number':this.items[index].unique_number})
          .then((response) => {
            this.items[index].messages=response.data.message;
            this.items[index].userStatus=response.data.status;
            setTimeout(() => {
              this.items[index].messages=null;
              this.items[index].userStatus=null;
            }, 3000);
            if(this.items[index].userStatus=="SUCCESS"){
              this.buttonDisabled=false;

            }else{
              this.buttonDisabled=true;
              this.items[index].unique_number=null;
              this.userDetail=null;

            }
            if(response.data.user){
              this.items[index].userDetail=response.data.user;
              this.items[index].candidate.first_name=this.items[index].userDetail.first_name;
              this.items[index].candidate.middle_name=this.items[index].userDetail.middle_name;
              this.items[index].candidate.last_name=this.items[index].userDetail.last_name;
              this.items[index].candidate.date_of_birth=this.items[index].userDetail.date_of_birth;
              this.items[index].candidate.day=parseInt(this.items[index].userDetail.day);
              this.items[index].candidate.month=this.items[index].userDetail.month;
              this.items[index].candidate.year=parseInt(this.items[index].userDetail.year);
            }
          })
          .catch(err => {

          }).finally(() => {
            this.candidate_overlay = false;
      });
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
              this.currency=this.scoreDetail.currency_symbol;
              this.getAllLocation();
              this.validationColourForTeacherInformation();
              this.validationColourForParentInformation();
              if(this.accountHolderDetail && this.accountHolderDetail.is_school_account && this.accountHolderDetail.accountholder_setting && this.accountHolderDetail.accountholder_setting.enable_school_pay && this.scoreDetail && this.scoreDetail.enable_school_pay){
                this.school_pay_button_enabled=true;
              }else{
                this.school_pay_button_enabled=false;
              }
            })
            .catch(err => {
            }).finally(() => {
          this.candidate_overlay = false;
        });
      }
    },
    //Teacher Information
    changeTeacherDetail(index){
      if(this.items[index].teacher_information.is_teacher){
        this.items[index].teacher_information.teacher_first_name=this.accountHolderDetail.first_name;
        this.items[index].teacher_information.teacher_middle_name=this.accountHolderDetail.middle_name;
        this.items[index].teacher_information.teacher_last_name=this.accountHolderDetail.last_name;
        this.items[index].teacher_information.teacher_email=this.accountHolderDetail.email;
        this.items[index].teacher_information.teacher_contact=this.accountHolderDetail.phone?this.accountHolderDetail.phone:this.accountHolderDetail.mobile;
      }else{
        this.items[index].teacher_information.teacher_first_name=null;
        this.items[index].teacher_information.teacher_middle_name=null;
        this.items[index].teacher_information.teacher_last_name=null;
        this.items[index].teacher_information.teacher_email=null;
        this.items[index].teacher_information.teacher_contact=null;
      }
    },
    //Parent Information
    changeParentDetail(index){
      if(this.items[index].parent_information.is_parent){
        this.items[index].parent_information.parent_first_name=this.accountHolderDetail.first_name;
        this.items[index].parent_information.parent_middle_name=this.accountHolderDetail.middle_name;
        this.items[index].parent_information.parent_last_name=this.accountHolderDetail.last_name;
        this.items[index].parent_information.parent_email=this.accountHolderDetail.email;
        this.items[index].parent_information.parent_contact=this.accountHolderDetail.phone?this.accountHolderDetail.phone:this.accountHolderDetail.mobile;
      }else{
        this.items[index].parent_information.parent_first_name=null;
        this.items[index].parent_information.parent_middle_name=null;
        this.items[index].parent_information.parent_last_name=null;
        this.items[index].parent_information.parent_email=null;
        this.items[index].parent_information.parent_contact=null;
      }
    },
    //SchedulingRequest
    getAllSchedulingRequest(){
      schedulingRequest
          .getAllSchedulingRequest({'score_id':this.scoreId})
          .then((response) => {
            this.scheduling_requests=response.data.scheduling_requests;

          })
          .catch(err => {
          });
    },
    changeSchedulingRequest(index){
      this.findSchedulingRequestDetail(index);
    },
    findSchedulingRequestDetail(index){
      if(this.items[index].exam_request.schedule_request_id){
        schedulingRequest
            .show(this.items[index].exam_request.schedule_request_id)
            .then((response) => {
              this.items[index].exam_request.schedulingRequestDetail=response.data.schedulingRequest;

            })
            .catch(err => {
            });
      }
    },
    //Examiner
    checkExaminer(examinerIndex){
      if(this.items[examinerIndex].exam_request.unsuitable_examiners.length>3){
        this.$snotify.error("You cannot add more than 3 Examiners");
        const index = this.items[examinerIndex].exam_request.unsuitable_examiners.pop();
        if (index > -1) {
          this.items[examinerIndex].exam_request.unsuitable_examiners.splice(index, 1);
        }

      }
    },
    //EnrolOption
    getGenderData(index){
      if(this.items[index].candidate.gender){
        if(this.items[index].candidate.gender=='self-described'){
          this.items[index].candidate.gender=this.gender_self_described;
        }

      }
      if(this.items[index].candidate.preferred_pronouns && this.items[index].candidate.preferred_pronouns=='self-described'){
        this.items[index].candidate.preferred_pronouns=this.items[index].prefer_to_described;
      }
    },
    buildFormData(){
      let data={};
      if(this.accountHolderDetail){
        data['accountHolderId']=this.accountHolderId;
        data['billing_address_id']=this.accountHolderDetail.accountholder_setting.default_billing_address_id;
      }
      if(this.productDetail){
        data['product_id']=this.productDetail.id;
      }

      this.items = this.items.map((item, index) => {
        let updatedItem = {
          ...item,
          candidate: {
            ...item.candidate,
            date_of_birth: `${item.candidate.year}-${item.candidate.month}-${item.candidate.day}`
          }
        };

        // Call anotherMethod with the index parameter
        this.getGenderData(index);
        return updatedItem;
      })
      data['exam']=this.exam;
      data['session']=this.session;
      data['items']=this.items;
      data['product_price']=this.product_price;
      data['cart_discount']=this.cart_discount;
      data['has_late_fee']=this.has_late_fee;

      return data;
    },
    scrollValidation(){
      this.$nextTick(() => {
        if(document.querySelector('.input--error-enrolment')){
          let domRect = document.querySelector('.input--error-enrolment').getBoundingClientRect();
          window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top-500 + document.documentElement.scrollTop
          );
        }
      })
    },
    AddToCartWithoutPayment(){
      this.$v.$touch();
      if(this.$v.$error){
        this.scrollValidation();
        setTimeout(() => {
          this.$v.$reset()
        }, 5500);
      }else{
        let data=this.buildFormData();
        this.cart_overlay=true;
        enrolPracticalExam
            .createCartWithOutPayment(data)
            .then((response) => {
              this.$snotify.success('Enrol Practical Candidate Succesfully');

              this.resetData();
            })
            .catch(err => {
            }).finally(() => {
          this.cart_overlay=false;
        });
      }
    },
    addToCartWithPayment(){
      this.$v.$touch()
      if(this.$v.$error){
        this.scrollValidation()
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
        return false;
      }else{
        let data=this.buildFormData();
        /* this.cart_overlay=true;*/
        enrolPracticalExam
            .createCartWithPayment(data)
            .then((response) => {
              // this.$snotify.success('Enrol Practical Candidate Successfully');
              if(response.data.cartId){
                if(this.appEnv != 'production'){
                  this.cartId = response.data.cartId;
                  this.showCart(response.data.cartId);
                }else{
                  this.goToSummaryPage(response.data.cartId)
                }
              }

            })
            .catch(err => {
            }).finally(() => {
          this.cart_overlay=false;
        });
      }
    },
    showCart(cartId){
      cart
      .show(cartId)
      .then((res) => {
        this.cart = res.data.cart;
        this.$refs['update-cart'].updateCart(this.cart,this.cart.user_id,this.cart.user_full_name,this.cart.user_score_id);
      })
      .catch((err) => {

      })
    },
    addToCartWithSchoolPayment(){
      this.$v.$touch()
      if(this.$v.$error){
        this.scrollValidation()
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
        return false;
      }else {
        let data = this.buildFormData();
        this.cart_overlay = true;
        enrolPracticalExam
            .createCartWithSchoolPayment(data)
            .then((response) => {
              this.$snotify.success('Enrol Practical Candidate Successfully');
              if(response.data.cartId){
                this.goToSummaryPage(response.data.cartId)
              }

            })
            .catch(err => {
            }).finally(() => {
          this.cart_overlay=false;
        });
      }
    },
    //Venue Cart
    verifyVenueCode(){
      venue
          .verifyVenueCode({'venue_code':this.venue_code,'score_id':this.scoreDetail.id})
          .then((response) => {
            this.venue_messages=response.data.message;
            this.venueStatus=response.data.status;
            if(this.venueStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
              this.venue_code=null;
              this.venueDetail= null;
              this.session.venue_id= null;
            }
            if(response.data.venue){
              this.venueDetail=response.data.venue;
              this.session.venue_id=this.venueDetail.id;
              this.session.venue_code=this.venue_code;
            }

          })
          .catch(err => {
          }).finally(() => {

      });
    },
    resetData(){
      this.exam={};
      this.session={};
      this.candidate={};
      this.teacher_information={};
      this.parent_information={};
      this.exam_request={};
      this.categoryDetail=null;
      this.instrumentDetail=null;
      this.syllabusDetail=null;
      this.gradeDetail=null;
      this.examTypeDetail=null;
      this.examOptionDetail=null;
      this.productDetail=null;
      this.venueDetail=null;
      this.scoreDetail=null;

      this.examSessionDetail=null;
      this.instrument_flag=false;
      this.syllabus_flag=false;
      this.exam_type_flag=false;
      this.exam_option_flag=false;
      this.preferred_pronouns_flag=false;
      this.getAccountHolderDetail();
      this.$v.$reset();
      this.subject_code='';
      this.subject_messages='';
      this.subjectCodeStatus=null;
      this.cart_discount={
        has_discount:false,
        discount_type:'fixed',
        discount_value:'',
      };
    },
    //Transaction Header
    getAllTransactionHeadings() {
      transactionHeader
          .getAllActive({'score_id':this.scoreId})
          .then((response) => {
            this.transaction_headers=response.data.transactionHeadings;
          })
          .catch(err => {
          }).finally(() => {

      })
    },
    //Route Page
    goToSummaryPage(cartId){
      this.$router.push({
        name:'manual-enrolment-step-3',
        params: {cartId:cartId}
      })
    },
    goToAccountHolderPage(accountHolderId,tabIndex){
      this.$tabs.open({
        name: "accountholder-edit",
        params: { accountholderId: accountHolderId, tabIndex:tabIndex }
      })
    },
    checkBillingAddress(){

      if(this.accountHolderDetail && this.accountHolderDetail.accountholder_setting && this.accountHolderDetail.accountholder_setting.default_billing_address_id){
        return this.address_flag=true;

      }else{
        this.address_flag=false;
      }
    },
    //Css
    validationColourForTeacherInformation(){

      if(this.scoreDetail){
        if(this.scoreDetail.required_teacher_information_on_enrolment){
          this.teacher_validation_colour_flag=true;
        }else{
          this.teacher_validation_colour_flag=false;
        }
      }
    },
    validationColourForParentInformation(){
      if(this.scoreDetail){
        if(this.scoreDetail.required_parent_information_on_enrolment){
          this.parent_validation_colour_flag=true;
        }else{
          this.parent_validation_colour_flag=false;
        }
      }
    },
    getCollaborativeInstrumentByCategory(){
      if(this.productDetail && this.productDetail.collaborative_instrument_category_id){
        collaborativeInstrument
            .getDataByCategory({'category_id':this.productDetail.collaborative_instrument_category_id})
            .then((response) => {
              this.collaborative_instruments=response.data.collaborative_instruments;
            })
            .catch(err => {
            }).finally(() => {

        });
      }

    },
    //Search By Subject Code
    searchBySubjectCode(){

      let data={};
      if(this.subject_code){
        data['subject_code']=this.subject_code;
        data['is_group_exam']=0;
        data['score_id']=this.scoreId;
        product
            .searchBySubjectCode(data)
            .then((response) => {
              this.subject_messages='We can\'t find a match for that today. Please try again.';
              this.subjectCodeStatus=response.data.status;

              if(this.subjectCodeStatus==="SUCCESS"){
                if(response.data.product){
                  this.productDetail=response.data.product;
                  if(this.productDetail){
                    if(this.productDetail.category_id){
                      this.exam.category_id=this.productDetail.category_id;
                    }
                    if(this.productDetail.instrument_id){
                      this.exam.instrument_id=this.productDetail.instrument_id;
                    }
                    if(this.productDetail.syllabus_id){
                      this.exam.syllabus_id=this.productDetail.syllabus_id;
                    }
                    if(this.productDetail.grade_id){
                      this.exam.grade_id=this.productDetail.grade_id;
                    }
                    if(this.productDetail.exam_delivery_method){
                      this.exam.exam_delivery_method=this.productDetail.exam_delivery_method;
                    }

                  }
                  this.getYearList();
                  this.getDayList();
                  this.getAllExamSession();
                  this.getAllSchedulingRequest();
                  this.getAllTransactionHeadings();
                  this.getCollaborativeInstrumentByCategory();
                  this.getProductPriceByScore();
                  this.getAllState();

                }
              }
              if(this.subjectCodeStatus==='ERROR'){
                this.subject_code=null;
                this.productDetail=null;
              }
              setTimeout(() => {
                this.subject_messages=null;
                this.subjectCodeStatus=null;
              }, 3000);
            })
            .catch(err => {
            })
            .finally(() => {
              this.subjectCodeLoading = false;
              this.exam_overlay = false;
            });
      }

    },
    changeSubjectCode(){
      this.resetData();
    },
    changeDiscountType(){
      if(this.cart_discount.discount_type=='fixed'){
        this.cart_discount.discount_value=null;
      }else{
        this.cart_discount.discount_value=null;
      }
    },
    removeCandidate(index){
      this.items.splice(index, 1);
    },
    addCandidate(){
      this.items.push( {
        unique_number:null,
        candidate:{
          first_name:'',
          middle_name:'',
          last_name:'',
          date_of_birth:'',
          day:'',
          month:'',
          year:'',
          gender:'',
          preferred_pronouns:'',
          tasc_id_tas:'',
          address_line_1:'',
          address_line_2:'',
          suburb:'',
          post_code:'',
          state_id:'',
          education_authority_submission_allowed:false,
          tasc_agreement:true,
          preferred_pronouns_flag:false,
        },
        recognition:{
          ameb_candidate_number:'',
          lui_number_qld:'',
          registration_year_qld:'',
          tasc_id_tas:'',
          address_line_1:'',
          address_line_2:'',
          suburb:'',
          state_id:'',
          post_code:'',
        },
        teacher_information:{
          teacher_first_name:'',
          teacher_middle_name:'',
          teacher_last_name:'',
          teacher_email:'',
          teacher_contact:'',
          teacher_school_name:'',
        },
        parent_information:{
          parent_first_name:'',
          parent_middle_name:'',
          parent_last_name:'',
          parent_email:'',
          parent_contact:'',
        },
        invigilator_information:{
          invigilator_first_name:'',
          invigilator_middle_name:'',
          invigilator_last_name:'',
          invigilator_contact:'',
          invigilator_email:'',
          invigilator_dob:'',
          date_menu:false,
        },
        exam_request:{
          schedule_request_id:'',
          scheduling_remarks:'',
          special_assistance_required:0,
          unsuitable_examiners:[],
          unsuitable_examiner_comments:'',
          special_assistance_remarks_by_user:'',
          special_assistance_remarks_by_admin:'',
          show_special_assistance_to_examiner:'',
          show_special_assistance_to_supervisor:'',
          timetable_special_assistance_option:'enroller',
          schedulingRequestDetail:null,
        },
        transaction:{
          transaction_heading_ids:''
        },

      });
    },
  },
  mounted() {
    this.getAccountHolderDetail();
  },
  computed:{
    rules(){
      return{
        subject_code: {
          required: requiredIf(function () {
            return this.subjectCodeSearch;
          })
        },
        exam:{
          location_id:{required},
          exam_delivery_method: {
            required: requiredIf(function () {
              return this.categoryDetail && this.categoryDetail.enable_video_delivery_mode && !this.subjectCodeSearch;
            })
          },
          category_id: {
            required: requiredIf(function () {
              return this.instrument_flag && !this.subjectCodeSearch;
            })
          },
          instrument_id: {
            required: requiredIf(function () {
              return this.instrument_flag && !this.subjectCodeSearch;
            })
          },
          syllabus_id: {
            required: requiredIf(function () {
              return this.syllabus_flag && !this.subjectCodeSearch;
            })
          },
          exam_type_id: {
            required: requiredIf(function () {
              return this.exam_type_flag && !this.subjectCodeSearch;
            })
          },
          exam_option_id: {
            required: requiredIf(function () {
              return this.exam_option_flag && !this.subjectCodeSearch;
            })
          },
          grade_id: {
            required: requiredIf(function () {
              return !this.subjectCodeSearch;
            })
          },

          hand_type: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_hand_selection;
            })
          },
          guitar_selection: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_guitar_selection && this.scoreDetail.requires_guitar_selection;
            })
          },
          drum_selection: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_drum_selection && this.scoreDetail && this.scoreDetail.requires_drum_selection;
            })
          },
          collaborative_instrument: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_collaborative_instrument;
            })
          },
        },
        cart_discount:{
          discount_type: {
            required: requiredIf(function () {
              return this.cart_discount.has_discount;
            })
          },
          discount_value: {
            required: requiredIf(function () {
              return this.cart_discount.has_discount;
            })
          },
        },
        session:{
          exam_session_id:{required},
        },
        items: {
          $each: {
            unique_number: {},
            candidate: {
              first_name: { required },
              last_name: { required },
              day: { required },
              month: { required },
              year: { required },
              gender:{
                required: requiredIf(function (nestedModel) {
                  return this.scoreDetail && this.scoreDetail.gender_identity;
                })
              },
              preferred_pronouns: {
                requiredIf: requiredIf(function (value) {
                  return  this.scoreDetail && this.scoreDetail.gender_identity && this.scoreDetail.preferred_pronoun_enabled && value.preferred_pronouns_flag;
                }),
              },
              tasc_id_tas:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
              address_line_1:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
              suburb:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
              state_id:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
              post_code:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
              tasc_agreement:{
                required: requiredIf(function (value) {
                  return this.scoreDetail && this.scoreDetail.purchase_prefix==='TAS' && value.education_authority_submission_allowed;
                })
              },
            },
            recognition: {
              ameb_candidate_number: {},

            },
            teacher_information: {
              teacher_first_name: {
                required: requiredIf(function () {
                  return  this.scoreDetail && this.scoreDetail.enable_enrolment_teacher_information && this.scoreDetail.required_teacher_information_on_enrolment;
                })
              },
              teacher_last_name: {
                required: requiredIf(function () {
                  return this.scoreDetail && this.scoreDetail.enable_enrolment_teacher_information &&  this.scoreDetail.required_teacher_information_on_enrolment
                })
              },
              teacher_email: {
                email,
                required: requiredIf(function () {
                  return this.scoreDetail && this.scoreDetail.enable_enrolment_teacher_information &&  this.scoreDetail.required_teacher_information_on_enrolment
                })
              },
              teacher_contact: {
                required: requiredIf(function () {
                  return this.scoreDetail && this.scoreDetail.enable_enrolment_teacher_information && this.scoreDetail.required_teacher_information_on_enrolment
                })
              },
              teacher_school_name: {
                required: requiredIf(function () {
                  return this.scoreDetail && this.scoreDetail.enable_enrolment_teacher_information && this.scoreDetail.required_teacher_information_on_enrolment && this.scoreDetail.require_school_name_on_enrolment_form;
                })
              },

            },
            parent_information: {
              parent_first_name: {
                required: requiredIf(function () {
                  return  this.scoreDetail && this.scoreDetail.enable_enrolment_parent_information && this.scoreDetail.required_parent_information_on_enrolment;
                })
              },
              parent_last_name: {
                required: requiredIf(function () {
                  return  this.scoreDetail && this.scoreDetail.enable_enrolment_parent_information && this.scoreDetail.required_parent_information_on_enrolment;
                })
              },
              parent_email:{
                email,
                required: requiredIf(function () {
                  return  this.scoreDetail && this.scoreDetail.enable_enrolment_parent_information && this.scoreDetail.required_parent_information_on_enrolment;
                })
              },
              parent_contact: {
                required: requiredIf(function () {
                  return this.scoreDetail && this.scoreDetail.enable_enrolment_parent_information &&  this.scoreDetail.required_parent_information_on_enrolment
                })
              },
            },
            invigilator_information: {
              invigilator_first_name: {
                required: requiredIf(function () {
                  return this.productDetail && this.productDetail.has_external_invigilator
                })
              },
              invigilator_last_name: {
                required: requiredIf(function () {
                  return this.productDetail && this.productDetail.has_external_invigilator
                })
              },
              invigilator_contact: {
                required: requiredIf(function () {
                  return this.productDetail && this.productDetail.has_external_invigilator
                })
              },
              invigilator_email: {
                email,
                required: requiredIf(function () {
                  return this.productDetail && this.productDetail.has_external_invigilator
                })
              },
              invigilator_dob: {
                required: requiredIf(function () {
                  return this.productDetail && this.productDetail.has_external_invigilator
                })
              },

            },
            exam_request: {
              special_assistance_remarks_by_user: {
                requiredIf: requiredIf(function (value) {

                  return  value.special_assistance_required;
                }),
              },
              scheduling_remarks: {
                requiredIf: requiredIf(function (value) {
                  return  value.schedulingRequestDetail && value.schedulingRequestDetail.require_additional_info;
                }),
              },
            },

          },
        },
        venueDetail: {
          required: requiredIf(function () {
            return this.scoreDetail && this.scoreDetail.enable_private_venues && this.examSessionDetail && this.examSessionDetail.enable_private_venue && this.examSessionDetail.is_private_venue_mandatory==true;
          })
        },
      }
    },
    accountHolderId() {
      return this.$route.params.accountHolderId;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  }
}
</script>
